export const Height = {
    header:50,
    subheader: 60,
    footer: 58
}


// export default Height;



