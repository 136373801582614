import React, { useEffect, useRef } from 'react';
import { Height } from '../../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { getId } from '../../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header + 10, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <Box sx={{ pl: 2, pr: 2 }}>
                {/* <Typography variant='h5' fontWeight={"bold"}>何謂動能投資法</Typography>
                <Typography variant='body1'>動能投資法是由Andreas F. Clenow所發明</Typography> */}

                <Typography sx={{ mt: 2, textAlign: "center" }} variant='h4' fontWeight={"bold"}>軟體說明</Typography>
                <Typography sx={{ mt: 2 }} variant='body1' >預期投資法是從"解讀市場預期"書本中得到的啟發後，進而針對台灣股市設計的一套投資軟體。下面會針對一些重要表格與操作來說明，若需要更詳盡的解說或原理的解釋，可以直接購買此書獲得解答。</Typography>
                {/* <img src='' style={{ width: "500px" }} /> */}

                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '40vh', md: '70vh' },
                        maxWidth: { xs: '40wh', md: '70wh' },
                    }}
                    alt="book1"
                    src="book1.jpg"
                />

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>預期投資法是什麼?</Typography>
                <Typography variant='body1'>
                    傳統評價一家公司的價值通常是基於該公司未來可能產生的現金流量，並將其以現值形式折現回來，最後與股價進行比較以判斷是否值得購買。這種方法將重點放在預測和評估公司未來的現金流量，並使用時間價值的概念將其轉換為現值。
                    <p></p>
                </Typography>
                <Typography variant='body1'>
                    預期投資法則是從股價開始進行評估，並回推出對應的價值。股價被視為隱含了一家公司在未來數年內可能產生的現金流量的現值總和。以簡單的例子來說（不考慮折現等因素），如果一家公司的股價為100元，且預計每年能賺取10元，那麼股價就隱含著預期持續10年的現金流。
                    <p></p>
                </Typography>
                <Typography variant='body1'>
                    然而，未來每年可以賺取多少錢與多個因素相關，例如營收成長率、營業利益率、固定資本投資增加和營運成本投資增加等。這意味著在樂觀或悲觀的營收成長情況下，以及在相同預期年限內，股價可能會有所不同。若股價偏離這些預估值太遠就是買賣的好時機。
                    <p></p>
                </Typography>
                <Typography variant='body1'>
                    更多詳細的定義與策略說明，請參閱解讀市場預期。
                    <p></p>
                </Typography>

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>預期表格說明</Typography>
                <Typography variant='body1'></Typography>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>股票：股票名稱與股價。點擊可以出現K線圖</Typography>
                    <Typography variant='body1'>類別：股票所在的類別。</Typography>
                    <Typography variant='body1'>預期年：股價隱含著預期年限。</Typography>
                    <Typography variant='body1'>預期價格：利用價格預估計算出最後的預期價格。</Typography>
                    <Typography variant='body1'>折溢價：股價相對於預期價格的折溢價是多少%。</Typography>
                    <Typography variant='body1'>價格預估：依據營收高中低成長條件，計算出的股價預估值。</Typography>
                    <Typography variant='body1'>營收成長預估：依據近幾年的營收成長預估出高中低的營收成長值。</Typography>
                </Stack>

                <Stack direction={"column"}>
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '50vh', md: '95vh' },
                            maxWidth: { xs: '50wh' },
                        }}
                        alt="book2"
                        src="exp_1.png"
                    />
                </Stack>

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>設定說明</Typography>
                <Typography variant='body1'></Typography>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>排序：折溢價跟預期年限可以由高到低或低到高排序</Typography>
                    <Typography variant='body1'>最大預期年限：計算股價隱含多少年限時的最大值，超過此值不顯示。</Typography>
                    <Typography variant='body1'>最小預期年限：預期期限小於此時，不顯示出來。</Typography>
                    <Typography variant='body1'>平均營收成長：用幾年去計算營收平均的成長率是多少。</Typography>
                    <Typography variant='body1'>預估高營收成長：比平均營收成長多少%為高營收成長。</Typography>
                    <Typography variant='body1'>預估低營收成長：比平均營收少多少%為低營收成長</Typography>
                    <Typography variant='body1'>折溢價最小值：折溢價低於多少不顯示，可能此股票不適用此方法評價。</Typography>
                    <Typography variant='body1'>折溢價最大值：折溢價高於多少不顯示，可能此股票不適用此方法評價。</Typography>
                    <Typography variant='body1'>折現率：未來現金折算成現值時的利率</Typography>
                </Stack>

                <Stack direction={"column"}>
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '50vh', md: '95vh' },
                            maxWidth: { xs: '50wh' },
                        }}
                        alt="book3"
                        src="expect_setting.png"
                    />
                                        <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '50vh', md: '95vh' },
                            maxWidth: { xs: '50wh' },
                        }}
                        alt="book3"
                        src="expect_setting1.png"
                    />
                </Stack>
                
            </Box>


            {/* <div class="container">
                    <div class="align-center mbr-p-1">
                        <p class="mbr-text mbr-white mbr-fonts-style display-7">
                            © Copyright 2025 喬可小舖股份有限公司 - All Rights Reserved
                        </p>
                    </div>
                </div> */}



        </Box>
    );
}
