import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../../util/AlertD';
import { getId, numberWithCommas } from '../../util/ID'
import InputAdornment from '@mui/material/InputAdornment';

const currencies = [
    {
        value: "0",
        label: "折溢價由低到高",
    },
    {
        value: "1",
        label: "折溢價由高到低",
    },
    {
        value: "2",
        label: '預期年限由低到高',
    },
    {
        value: "3",
        label: '預期年限由高到低',
    }
];

const revYearLabels = [
    {
        value: "2",
        label: "2年",
    },
    {
        value: "3",
        label: "3年",
    },
    {
        value: "4",
        label: '4年',
    },
    {
        value: "5",
        label: '5年',
    },
    {
        value: "6",
        label: '6年',
    },
    {
        value: "7",
        label: '7年',
    }
];

export default function SettingDialog(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [upRev, setUpRev] = React.useState(localStorage.getItem("expect_up_rev") != null ? localStorage.getItem("expect_up_rev") : 30);
    const [downRev, setDownRev] = React.useState(localStorage.getItem("expect_down_rev") != null ? localStorage.getItem("expect_down_rev") : -30);
    const [minDp, setMinDp] = React.useState(localStorage.getItem("expect_min_dp") != null ? localStorage.getItem("expect_min_dp") : -300);
    const [maxDp, setMaxDp] = React.useState(localStorage.getItem("expect_max_dp") != null ? localStorage.getItem("expect_max_dp") : 300);
    const [year, setYear] = React.useState(localStorage.getItem("expect_year") != null ? localStorage.getItem("expect_year") : 30);
    const [minYear, setMinYear] = React.useState(localStorage.getItem("expect_min_year") != null ? localStorage.getItem("expect_min_year") : 1);
    const [revYear, setRevYear] = React.useState(localStorage.getItem("expect_rev_year") != null ? localStorage.getItem("expect_rev_year") : 5);
    const [discountRate, setDiscountRate] = React.useState(localStorage.getItem("expect_discount_rate") != null ? localStorage.getItem("expect_discount_rate") : 10);


    const [sort, setSort] = React.useState(localStorage.getItem("expect_sort") != null ? localStorage.getItem("expect_sort") : "0");


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (upRev != "" && upRev != null) localStorage.setItem("expect_up_rev", upRev)
        if (downRev != "" && downRev != null) localStorage.setItem("expect_down_rev", downRev)
        if (minDp != "" && minDp != null) localStorage.setItem("expect_min_dp", minDp)
        if (maxDp != "" && maxDp != null) localStorage.setItem("expect_max_dp", maxDp)
        if (year != "" && year != null) localStorage.setItem("expect_year", year)
        if (minYear != "" && minYear != null) localStorage.setItem("expect_min_year", minYear)
        if (sort != "" && sort != null) localStorage.setItem("expect_sort", sort)
        if (revYear != "" && revYear != null) localStorage.setItem("expect_rev_year", revYear)
        if (discountRate != "" && discountRate != null) localStorage.setItem("expect_discount_rate", discountRate)

        
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    設定
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 0 }}>顯示條件</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>

                        <TextField
                            size="medium"
                            id="A"
                            select
                            label="排序"
                            value={sort}
                            onChange={event => setSort(event.target.value)}
                            helperText="排序顯示方式，預設：折溢價由低到高"
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField size="medium" label="最大預期年限" fullWidth
                            value={year}
                            onChange={event => setYear(event.target.value)}
                            helperText="預期年限最大值，超過不顯示，預設：30年"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">年</InputAdornment>,
                            }}
                        ></TextField>
                        <TextField size="medium" label="最小預期年限" fullWidth
                            value={minYear}
                            onChange={event => setMinYear(event.target.value)}
                            helperText="預期年限最小值，小於不顯示，預設：1年"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">年</InputAdornment>,
                            }}
                        ></TextField>

                        <TextField
                            size="medium"
                            id="R"
                            select
                            label="平均幾年營收成長"
                            value={revYear}
                            onChange={event => setRevYear(event.target.value)}
                            helperText="用幾年的營收成長來平均，預設：5年"
                        >
                            {revYearLabels.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField size="medium" label="預估高營收成長" fullWidth
                            value={upRev}
                            onChange={event => setUpRev(event.target.value)}
                            helperText="比平均營收成長多少%，才稱作是高成長，預設：30%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>

                        <TextField size="medium" label="預估低營收成長" fullWidth
                            value={downRev}
                            onChange={event => setDownRev(event.target.value)}
                            helperText="比平均營收減少多少%，才稱作是低成長，預設：30%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>

                        <TextField size="medium" label="折溢價最小值" fullWidth
                            value={minDp}
                            onChange={event => setMinDp(event.target.value)}
                            helperText="折溢價下限為多少%，小於不顯示，預設：-300%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>

                        <TextField size="medium" label="折溢價最大值" fullWidth
                            value={maxDp}
                            onChange={event => setMaxDp(event.target.value)}
                            helperText="折溢價上限為多少%，大於不顯示，預設：300%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>
                        <TextField size="medium" label="折現率" fullWidth
                            value={discountRate}
                            onChange={event => setDiscountRate(event.target.value)}
                            helperText="預設：10%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button sx={{  }} onClick={handleClose}>取消</Button>
                    {localStorage.getItem("exp_noad") === "1" ? <Button sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>:<Button disabled sx={{  }} autoFocus>
                        確定 (VIP專屬)
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
