import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../../util/ID'
import { putInpQueryParam } from '../../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../../util/ScreenHeight'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import SliderValues from './SliderValues';
import RevTab from './RevTab';
import SettingDialog from './SettingDialog';
import AlertD from '../../util/AlertD'
import DetailKChart from './DetailKChart'
import StrageDialog from './StrageDialog';
import SearchCompany from './SearchCompany';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // fontWeight:"bold",
    fontSize: 18,
    whiteSpace: "nowrap"
    // backgroundColor: "#313f57",
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function HomeTable(props) {
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(1)
  const [result, setResult] = React.useState(null);
  const [loading, setLoading] = React.useState(false)

  const [buyNum, setBuyNum] = React.useState(0)

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState(null);
  const [openSetting, setOpenSetting] = React.useState(false);
  const [openSetting1, setOpenSetting1] = React.useState(false);
  const [openSetting2, setOpenSetting2] = React.useState(false);
  const [openSetting3, setOpenSetting3] = React.useState(false);
  const [openSetting4, setOpenSetting4] = React.useState(false);

  const [sid, setSid] = React.useState(null);
  const [sid1, setSID1] = React.useState(null);

  const [row, setRow] = React.useState(null);


  const [age, setAge] = React.useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const openDetail2 = (id) => {
    setSid(id)
    setOpenSetting2(true)
  }

  const openDetail3 = (id) => {
    setSid(id)
    setOpenSetting3(true)
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const fetchInitData = () => {
    setPage(1)
    fetchDataInter(1)
  }


  const fetchData = () => {
    fetchDataInter(page)
  }

  const fetchDataInter = (pv) => {
    setLoading(true)

    let rules = localStorage.getItem('expect_search_rules') != null ? JSON.parse(localStorage.getItem('expect_search_rules')) : []

    let param = {
      page: pv,
      rules: rules,
      stock: sid1,
      inp: {}
    }

    if (localStorage.getItem("expect_lic") != null) {
      param['lic'] = localStorage.getItem("expect_lic")
    }

    if (localStorage.getItem("expect_up_rev") != null) {
      param['up_rev'] = localStorage.getItem("expect_up_rev")
    }

    if (localStorage.getItem("expect_down_rev") != null) {
      param['down_rev'] = localStorage.getItem("expect_down_rev")
    }

    if (localStorage.getItem("expect_min_dp") != null) {
      param['min_dp'] = localStorage.getItem("expect_min_dp")
    }

    if (localStorage.getItem("expect_max_dp") != null) {
      param['max_dp'] = localStorage.getItem("expect_max_dp")
    }

    if (localStorage.getItem("expect_year") != null) {
      param['max_year'] = localStorage.getItem("expect_year")
    }

    if (localStorage.getItem("expect_min_year") != null) {
      param['min_year'] = localStorage.getItem("expect_min_year")
    }

    if (localStorage.getItem("expect_sort") != null) {
      param['sort'] = localStorage.getItem("expect_sort")
    }

    if (localStorage.getItem("expect_rev_year") != null) {
      param['rev_year'] = localStorage.getItem("expect_rev_year")
    }

    if (localStorage.getItem("expect_discount_rate") != null) {
      param['discount_rate'] = localStorage.getItem("expect_discount_rate")
    }

    param = putInpQueryParam(param)

    fetch("/move/v1/expect/getAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(param)
    })
      .then(res => res.json())
      .then(
        result => {
          setLoading(false)

          if (result != null) {
            if (result.data != null && result.data.length > 0) {
              setTotal(result.total_page)
              setResult(result.data)
              setBuyNum(result.count)
            } else {
              setTotal(0)
              setResult([])
            }
          }
        }
      );
  }

  useEffect(() => {
    fetchData()
  }, [page, sid1]);

  const getColor = (row) => {
    if (row != null && row.price != null) {
      if (row.price.i > 0) return red[400]
      else if (row.price.i < 0) return green[400]
      else return null
    } else return null
  }

  const getInv = (row) => {
    if (row != null && row.price != null) {
      let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

      if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
      else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
      else return row.price.i + '元' + ' (' + pp + '%)'
    } else return '▲0元'
  }

  const getColor1 = (row) => {
    if (row != null && row.price != null) {
      if (row.price.i > 0) return red[400]
      else if (row.price.i < 0) return green[400]
      else return null
    } else return null
  }

  const openDetail = (row) => {
    setRow(row)
    setOpenSetting(true)
  }

  const getRank = () => {
    return localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30
  }

  const isRankErr = (row) => {
    return (row.rank > getRank())
  }

  const getColor2 = (value) => {
    if (value > 0) return "error"
    else if (value < 0) return "success"
    else if (value === 0) return "inherit"
    else return "inherit"
  }

  const getVariant2 = (value) => {
    if (value < -30 || value > 30) return "contained"
    else if (value >= -30 && value < 30) return "outlined"
    else return "outlined"
  }

  const getColor3 = (value) => {
    if (value > 0) return red[400]
    else if (value < 0) return green[400]
    else return null
  }

  const clickLove = (row) => {
    let param = {
      sid: row.company.stock_no,
      sn: getId(),
    }

    if (localStorage.getItem("expect_lic") != null) {
      param['lic'] = localStorage.getItem("expect_lic")
    }

    fetch("/move/v1/expect/love/click", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(param)
    })
      .then(res => res.json())
      .then(
        result => {

          if (result != null && result === 0) {
            setAlertMsg("新增成功")
            setShowAlert(true)
          }
        }
      );
  }


  return (
    <>
      {/* {openSetting ? <BuyDialog row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></BuyDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <RuleDialog refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></RuleDialog> : null}
            {openSetting2 && <DetailKChart  sid={sid} open={openSetting2} close={setOpenSetting2} />}            
            {openSetting3 && <StockHistoryDialog  sid={sid} open={openSetting3} close={setOpenSetting3} />}            
            {openSetting4 && <StrageDialog open={openSetting4} close={setOpenSetting4} refresh={fetchData}/>}             */}
      {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
      {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}
      {openSetting1 ? <SettingDialog refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></SettingDialog> : null}
      {openSetting4 && <StrageDialog open={openSetting4} close={setOpenSetting4} refresh={fetchInitData} />}

      <Box sx={{ p: 0, width: "100%", overflowY: 'hidden' }}>
        {/* 投資組合：{buyNum}檔 */}

        <Typography sx={{ mt: 2, textAlign: "center" }} variant="h4">市場預期表
          <Button onClick={e => setOpenSetting1(true)}>設定</Button>
          <Button onClick={e => setOpenSetting4(true)}>過濾</Button>
        </Typography>

        <TableContainer component={Paper} sx={{ position: 'absolute', top: Height.header + 60, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>


          {/* <Button onClick={e => setOpenSetting1(true)} fullWidth>總資金：{numberWithCommas(getCapital())}元，買進條件：市值前{getMC()}名...</Button> */}


          <Table sx={{ minWidth: 700 }} stickyHeader size="small">
            <TableHead>
              <TableRow >
                <StyledTableCell>
                  <Stack direction={"row"} spacing={1}>
                    <Box sx={{ alignSelf: "center" }}>股票</Box>
                    <SearchCompany setSID={setSID1} ></SearchCompany>
                  </Stack>


                </StyledTableCell>
                <StyledTableCell align="center">類股</StyledTableCell>
                <StyledTableCell align="center">預期年</StyledTableCell>
                <StyledTableCell align="center">預期價格</StyledTableCell>
                <StyledTableCell align="center">折溢價</StyledTableCell>
                <StyledTableCell align="center">最新營收YoY</StyledTableCell>

                <StyledTableCell align="center">價格預估</StyledTableCell>
                <StyledTableCell align="center">營收成長預估</StyledTableCell>
                <StyledTableCell align="center">動作</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result != null && result.map((row) => (
                <StyledTableRow key={row.company.stock_no}>

                  <StyledTableCell onClick={e => openDetail2(row.company.stock_no)} >
                    {row.company.short_name} {row.company.stock_no}

                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                      <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                      <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" >
                    {row.company.inductry}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.expect_year}年
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.expect_price}元
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button disableElevation fullWidth variant={getVariant2(row.dp)} color={getColor2(row.dp)}>{row.dp}%</Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography color={getColor3(row.cur_rev_yo_y)}>{row.cur_rev_yo_y}%</Typography>

                  </StyledTableCell>

                  <StyledTableCell>
                    <RevTab value1={row.max_price} value2={row.avg_price}
                      value3={row.min_price} unit={"元"}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <RevTab value1={row.max_rev} value2={row.avg_rev}
                      value3={row.min_rev} unit={"%"}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Button onClick={e => clickLove(row)} disableElevation variant='contained' sx={{
                      bgcolor: grey[600], ':hover': {
                        bgcolor: grey[600], // theme.palette.primary.main
                        color: 'white',
                      }
                    }}>加我的最愛</Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
        <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
      </Box>}
    </>
  );
}
