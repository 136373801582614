import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../../util/AlertD';
import { getId, numberWithCommas } from '../../util/ID'
import InputAdornment from '@mui/material/InputAdornment';


const revYearLabels = [
    {
        value: "2",
        label: "2年",
    },
    {
        value: "3",
        label: "3年",
    },
    {
        value: "4",
        label: '4年',
    },
    {
        value: "5",
        label: '5年',
    },
    {
        value: "6",
        label: '6年',
    },
    {
        value: "7",
        label: '7年',
    }
];

export default function SingleSettingDialog(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [upRev, setUpRev] = React.useState(30);
    const [downRev, setDownRev] = React.useState(-30);
    const [year, setYear] = React.useState(30);
    const [revYear, setRevYear] = React.useState(5);
    const [discountRate, setDiscountRate] = React.useState(10);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        let param = {
            sid: props.row.ed.company.stock_no,
            sn: getId(),
            up_rev: upRev,
            down_rev: downRev,
            max_year: year,
            rev_year: revYear,
            discount_rate: discountRate
        }

        if (localStorage.getItem("expect_lic") != null) {
            param['lic'] = localStorage.getItem("expect_lic")
        }

        fetch("/move/v1/expect/love/param", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setOpen(false);
                    if (props.close != null) props.close(false)
                    if (props.refresh != null) props.refresh()
                }
            );


    };


    useEffect(() => {
        if (props.open) {
            if (props.row != null && props.row.el != null && props.row.el.param != null) {
                setUpRev(props.row.el.param.up_rev)
                setDownRev(props.row.el.param.down_rev)
                setYear(props.row.el.param.max_year)
                setRevYear(props.row.el.param.rev_year)
                setDiscountRate(props.row.el.param.discount_rate)
            }
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {props.row.ed.company.short_name}單獨設定
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    {/* <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 0 }}>顯示條件</Typography> */}
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>


                        <TextField size="medium" label="最大預期年限" fullWidth
                            value={year}
                            onChange={event => setYear(event.target.value)}
                            helperText="預期年限最大值，超過不顯示，預設：30年"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">年</InputAdornment>,
                            }}
                        ></TextField>

                        <TextField
                            size="medium"
                            id="R"
                            select
                            label="平均幾年營收成長"
                            value={revYear}
                            onChange={event => setRevYear(event.target.value)}
                            helperText="用幾年的營收成長來平均，預設：5年"
                        >
                            {revYearLabels.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField size="medium" label="預估高營收成長" fullWidth
                            value={upRev}
                            onChange={event => setUpRev(event.target.value)}
                            helperText="比平均營收成長多少%，才稱作是高成長，預設：30%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>

                        <TextField size="medium" label="預估低營收成長" fullWidth
                            value={downRev}
                            onChange={event => setDownRev(event.target.value)}
                            helperText="比平均營收減少多少%，才稱作是低成長，預設：30%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>
                        <TextField size="medium" label="折現率" fullWidth
                            value={discountRate}
                            onChange={event => setDiscountRate(event.target.value)}
                            helperText="預設：10%"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button sx={{  }} onClick={handleClose}>取消</Button>
                    {localStorage.getItem("exp_noad") === "1" ? <Button sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>:<Button disabled sx={{ }} autoFocus>
                        確定 (VIP專屬)
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
