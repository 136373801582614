import React, { useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { Divider, TextField } from '@mui/material';
import { TitleRounded } from '@mui/icons-material';
import { Alert } from '../../util/ErrorMsg'
import Snackbar from '@mui/material/Snackbar';
import { Height } from '../../util/ScreenHeight'
import Stack from '@mui/material/Stack';
import { getId, getLic } from '../../util/ID'
import LicenseBindDialog from './LicenseBindDialog';


function PricingContentg(props) {
    const [openError, setOpenError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState(null)

    const [inlic, setInLic] = React.useState("");
    const [lic, setLic] = React.useState(null);
    const [lictxt, setLictxt] = React.useState("");

    const [openSetting, setOpenSetting] = React.useState(false);

    const [name, setName] = React.useState(null);
    const [phone, setPhone] = React.useState(null)
    const [mapTxt, setMapTxt] = React.useState("");

    const fectchAPIK = (tp) => {
        if (tp === '0') {
        } else {
            let lic = tp.toString() + '' + getLic()
            localStorage.setItem('expect_lic', lic);

            let url = "/move/v1/payment/donate?sn=" + getId() + "&tp=" + tp + "&lic=" + lic + "#open"
            window.open(url, "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
        }
    }

    useEffect(() => {
        checkLic();
    }, []);

    const checkLic = () => {
        let lic = localStorage.getItem('expect_lic')

        if (lic != null) {
            let param = {
                lic: lic,
                sn: getId()
            }

            fetch("/move/v1/payment/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null) {
                                setLic(result.hlic)

                                if (result.hlic.licok === 1 || result.hlic.tp === 3) {
                                    localStorage.setItem('expect_noad', 1);
                                } else localStorage.setItem('expect_noad', 0);

                            } else {
                                localStorage.setItem('expect_noad', 0);
                            }
                        } else {
                            localStorage.setItem('expect_noad', 0);
                        }
                    },
                    error => {
                        localStorage.setItem('expect_noad', 0);
                    }
                );
        }
    }



    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };

    const getBottom = () => {
        if (props.ismobile) {
            return 58
        } else {
            return 0
        }
    }

    const bindLic = () => {
        if (localStorage.getItem("expect_lic") != null) {
            setOpenSetting(true)
        }
    }

    const loginByNamePhone = () => {
        if (name != null && phone != null && name != "" && phone != "") {
            let param = {
                phone: phone,
                name: name
            }

            fetch("/move/v1/payment/checklic3", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null) {
                                setLic(result.hlic)
                                setMapTxt("")

                                if (result.hlic.licok === 1) {
                                    localStorage.setItem('expect_lic', result.hlic.lic);
                                    localStorage.setItem('noad', 1);
                                } else localStorage.setItem('noad', 0);

                            } else {
                                localStorage.setItem('noad', 0);
                                setMapTxt("此序號不存在")
                            }
                        } else {
                            localStorage.setItem('noad', 0);
                            setMapTxt("此序號不存在")
                        }
                    },
                    error => {
                        localStorage.setItem('noad', 0);
                        setMapTxt("此序號不存在")
                    }
                );
        } else {
            setErrorMsg("輸入姓名與電話後五碼")
            setOpenError(true)
        }
    }

    const checkLicByLic = () => {
        let lic = inlic

        if (lic != null) {
            let param = {
                lic: lic,
                sn: getId()
            }

            fetch("/move/v1/payment/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null) {
                                setLic(result.hlic)
                                setLictxt("")

                                if (result.hlic.licok === 1) {
                                    localStorage.setItem('expect_lic', lic);
                                    localStorage.setItem('expect_noad', 1);
                                } else localStorage.setItem('expect_noad', 0);

                            } else {
                                localStorage.setItem('expect_noad', 0);
                                setLictxt("此序號不存在")
                            }
                        } else {
                            localStorage.setItem('expect_noad', 0);
                            setLictxt("此序號不存在")
                        }
                    },
                    error => {
                        localStorage.setItem('expect_noad', 0);
                        setLictxt("此序號不存在")
                    }
                );
        }
    }

    const inputLic = event => {
        setInLic(event.target.value)
    }

    const getTitle = () => {
        if (lic === null) return '免費'
        else if (lic.tp === 74) return '無使用期限'
        // else if (lic.tp === 0 || lic.tp === 15 || lic.tp === 34) return '月訂制'
        // else if (lic.tp === 35 || lic.tp === 7) return '年訂制'
        else return ''
    }

    const getExpireTitle = () => {
        if (lic.expire != null)
            return lic.expire
        // else if (lic.tp != null && (lic.tp === 0 || lic.tp === 7 || lic.tp === 15 || lic.tp === 34 || lic.tp === 35))
        //     return "訂閱期間有效"
        // else if (lic.tp != null && (lic.tp === 16))
        //     return "三年方案"
        else
            return '無特定期限'
    }

    const clearLic = () => {
        localStorage.removeItem('expect_lic')
        localStorage.setItem('expect_noad', 0);
        setLictxt("")
        setLic(null)
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {openSetting ? <LicenseBindDialog fectchAPIK={fectchAPIK} open={openSetting} close={setOpenSetting}></LicenseBindDialog> : null}

            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            <Snackbar open={openError} autoHideDuration={3000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="warning" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ mt: 2.5, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                {/* <Toolbar sx={{ flexWrap: 'wrap' }}> */}
                <Stack direction={"row"} sx={{ p: 1 }}>
                    <AutoAwesome style={{ width: 22, height: 22, marginRight: 5 }} />
                    <Typography color="inherit" noWrap sx={{ flexGrow: 1, fontSize: 16 }}>
                        方案列表
                    </Typography>
                </Stack>
            </AppBar>
            {/* Hero unit */}
            {/* ,position: 'absolute', top: getTop(), bottom: getBottom(), overflowY: 'auto' */}
            <Box sx={{ border: 'solid', borderColor: "#d9d9d9", borderWidth: 1, flexGrow: 1, p: 1.5, pb: 5, position: 'absolute', top: Height.header + Height.subheader, bottom: getBottom(), overflowY: 'auto', width: '100%' }}>

                {/* <Box sx={{ border: 'solid', borderColor: "#d9d9d9", borderWidth: 1, flexGrow: 1, p: 1.5, pb: 5, overflowY: 'scroll' }}> */}
                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 2 }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        購買序號
                    </Typography>
                </Container>

                <Container maxWidth="md" component="main">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item key={"0"} xs={12} sm={4} md={4} >
                            <Card>
                                <CardHeader
                                    title={"免費"}
                                    subheader={"完全免費"}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $0
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            / 一次性
                                        </Typography>
                                    </Box>
                                    <ul>
                                        <Typography component="li" variant="subtitle2" align="center" key={'1'}>
                                            搜尋隨機顯示幾筆結果
                                        </Typography>
                                        <Typography component="li" variant="subtitle2" align="center" key={'2'}>
                                            預期投資表不可參數設定
                                        </Typography>
                                        <Typography component="li" variant="subtitle2" align="center" key={'3'}>
                                            我的最愛中股票不可個別參數設定
                                        </Typography>
                                        <Typography component="li" variant="subtitle2" align="center" key={'4'}>
                                            可能會有廣告出現
                                        </Typography>
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button color="inherit" disableElevation fullWidth variant={"contained"} onClick={e => fectchAPIK(0)}>
                                        直接使用
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>


                        <Grid item key={"2a"} xs={12} sm={8} md={8} >
                            <Card>
                                <CardHeader
                                    title={"優惠價"}
                                    subheader={""}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $888
                                        </Typography>
                                        <Typography sx={{ textDecoration: "line-through" }} variant="body2" color="text.primary">
                                            $1288
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            / 一次性
                                        </Typography>
                                    </Box>
                                    <ul>
                                        <Typography component="li" variant="subtitle2" align="center" key={'1'}>
                                            買斷，無使用期限
                                        </Typography>
                                        <Typography component="li" variant="subtitle2" align="center" key={'2'}>
                                            預期投資表可以參數設定
                                        </Typography>
                                        <Typography component="li" variant="subtitle2" align="center" key={'3'}>
                                            我的最愛中股票可以個別參數設定
                                        </Typography>
                                        <Typography component="li" variant="subtitle2" align="center" key={'4'}>
                                            無廣告出現
                                        </Typography>
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button color="error" disableElevation fullWidth variant={"contained"} onClick={e => fectchAPIK(75)}>
                                        立即購買
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>

                </Container>

                <Divider sx={{ mt: 8, mb: 8 }} />

                {lic != null && (lic.licok === 1) ?
                    <Container maxWidth="md" component="main" style={{ marginTop: 30, marginBottom: 30 }}>
                        <Grid container spacing={5} alignItems="flex-end">
                            <Grid item key={"aa"} xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader
                                        title={getTitle()}
                                        // subheader={<div>說明書 <Link onClick={(e) => window.open('stock-candle.pdf')}>下載</Link></div>}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        action={<StarIcon />}
                                    />
                                    <CardContent>
                                        <div >
                                            <Typography align="center" variant="h6" color="textPrimary">
                                                序號
                                            </Typography>
                                        </div>
                                        <div >
                                            <Typography align="center" component="h2" variant="h3" color="textPrimary">
                                                {lic.lic}
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary">

                                            </Typography>
                                        </div>

                                        <ul>
                                            <Typography component="li" variant="subtitle2" align="center" key={Math.random()}>
                                                使用期限：{getExpireTitle()}
                                            </Typography>
                                            <Typography component="li" variant="caption" align="center" color="textSecondary">
                                                妥善保管，勿公開給他人使用
                                            </Typography>
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <Button style={{ width: '60%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => clearLic()}>清除
                                        </Button>
                                        <Button style={{ width: '40%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="error" onClick={event => bindLic()}>綁定
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container> : <Container maxWidth="md" component="main" style={{ marginTop: 30, marginBottom: 30 }}>
                        <Grid container spacing={5} alignItems="flex-end">
                            <Grid item key={"aa1"} xs={12} sm={6} md={6} sx={{alignSelf:"start"}}>
                                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 0, pb: 0 }}>
                                    <Typography
                                        component="h1"
                                        variant="h2"
                                        align="center"
                                        color="text.primary"
                                        gutterBottom
                                    >
                                        輸入序號
                                    </Typography>
                                </Container>


                                <Card>

                                    <CardContent>
                                        <TextField
                                            id="standard-name"
                                            label="輸入序號"
                                            onChange={e => inputLic(e)}
                                            fullWidth
                                            style={{ fontSize: 18 }}
                                            helperText={lictxt}
                                        />
                                        <br />
                                        <Typography variant="caption" color="textSecondary" component="p">
                                            <li>收到付款完成通知信，商店訂單編號前面8碼即是序號</li>
                                            <li>相同序號皆可共用於手機，平板，電腦等平台</li>
                                            {/* <li>如何取得序號? <Link onClick={(e) => handleOk1()}>請洽官網方案列表</Link></li> */}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button style={{ width: '100%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => checkLicByLic()}>送出
                                        </Button>

                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item key={"4"} xs={12} sm={6} md={6}>
                                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 0, pb: 0 }}>
                                    <Typography
                                        component="h1"
                                        variant="h2"
                                        align="center"
                                        color="text.primary"
                                        gutterBottom>
                                        輸入綁定資訊
                                    </Typography>
                                </Container>
                                <Card>
                                    <CardContent>
                                        <Stack direction={"column"} spacing={1}>
                                            <TextField
                                                id="name"
                                                label="姓名"
                                                onChange={e => setName(e.target.value)}
                                                fullWidth
                                                style={{ fontSize: 18 }}
                                            />
                                            <TextField
                                                id="phone"
                                                label="手機後五碼"
                                                onChange={e => setPhone(e.target.value)}
                                                fullWidth
                                                style={{ fontSize: 18 }}
                                                helperText={mapTxt}
                                            />
                                            <Typography variant="caption" color="textSecondary" component="p">
                                                <li>若之前已經將序號綁定在個人資訊上，可直接輸入姓名與手機後五碼，即可自動帶進序號</li>
                                                {/* <li>如何取得序號? <Link onClick={(e) => handleOk1()}>請洽官網方案列表</Link></li> */}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <Button style={{ width: '100%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => loginByNamePhone()}>送出
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>

                        </Grid>
                    </Container>}



            </Box>


        </div>
    );
}

export default function PaymentContainer() {



    return <PricingContentg />;
}