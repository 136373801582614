import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Stack } from '@mui/material';

export default function RevTab(props) {
  return (
    <>
      {/* <Stack direction={"column"} spacing={0.2} >
        <ButtonGroup size="small" fullWidth color="inherit" variant="text" disableElevation >
          <Button>高</Button>
          <Button>中</Button>
          <Button>低</Button>
        </ButtonGroup> */}
        <ButtonGroup size="small" fullWidth color="secondary" variant="contained" disableElevation >
          <Button >{props.value1}{props.unit}</Button>
          <Button>{props.value2}{props.unit}</Button>
          <Button>{props.value3}{props.unit}</Button>
        </ButtonGroup>
      {/* </Stack> */}
    </>
  );
}