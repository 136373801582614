import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import { getId, numberWithCommas } from '../../util/ID'
import { putInpQueryParam } from '../../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../../util/ScreenHeight'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import SliderValues from './SliderValues';
import RevTab from './RevTab';
import AddLoveDialog from './AddLoveDialog';
import AlertD from '../../util/AlertD'
import SingleSettingDialog from './SingleSettingDialog';
import IconButton from '@mui/material/IconButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 18,
        whiteSpace: "nowrap"
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function LoveTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [buyNum, setBuyNum] = React.useState(0)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);

    const [sid, setSid] = React.useState(null);

    const [row, setRow] = React.useState(null);


    const [age, setAge] = React.useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const fetchInitData = () => {
        setPage(1)
        fetchDataInter(1)
    }


    const fetchData = () => {
        fetchDataInter(page)
    }

    const fetchDataInter = (pv) => {
        setLoading(true)

        let param = {
            sn: getId(),
        }

        if (localStorage.getItem("expect_lic") != null) {
            param['lic'] = localStorage.getItem("expect_lic")
        }


        param = putInpQueryParam(param)

        fetch("/move/v1/expect/love/getAll", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.love != null && result.love.length > 0) {
                            setTotal(result.total_page)
                            setResult(result.love)
                            setBuyNum(result.count)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page]);

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getColor1 = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const openDetail = (row) => {
        setRow(row)
        setOpenSetting(true)
    }

    const getRank = () => {
        return localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30
    }

    const isRankErr = (row) => {
        return (row.rank > getRank())
    }

    const getColor2 = (value) => {
        if (value > 0) return "error"
        else if (value < 0) return "success"
        else if (value === 0) return "inherit"
        else return "inherit"
    }

    const getVariant2 = (value) => {
        if (value < -30 || value > 30) return "contained"
        else if (value >= -30 && value < 30) return "outlined"
        else return "outlined"
    }

    const getColor3 = (value) => {
        if (value > 0) return red[400]
        else if (value < 0) return green[400]
        else return null
    }

    const clickSetting = (row) => {
        setRow(row)
        setOpenSetting1(true)
    }

    const removeLove = (row) => {
        let param = {
            sid: row.company.stock_no,
            sn: getId(),
        }

        if (localStorage.getItem("expect_lic") != null) {
            param['lic'] = localStorage.getItem("expect_lic")
        }

        fetch("/move/v1/expect/love/click", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result === 0) {
                        fetchData()
                    }
                }
            );
    }


    return (
        <>
            {/* {openSetting ? <BuyDialog row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></BuyDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <RuleDialog refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></RuleDialog> : null}
            {openSetting2 && <DetailKChart  sid={sid} open={openSetting2} close={setOpenSetting2} />}            
            {openSetting3 && <StockHistoryDialog  sid={sid} open={openSetting3} close={setOpenSetting3} />}            
            {openSetting4 && <StrageDialog open={openSetting4} close={setOpenSetting4} refresh={fetchData}/>}             */}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <SingleSettingDialog row={row} refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></SingleSettingDialog> : null}
            {openSetting2 ? <AddLoveDialog  refresh={fetchData} open={openSetting2} close={setOpenSetting2}></AddLoveDialog> : null}

            <Box sx={{ p: 0, width: "100%", overflowY: 'hidden' }}>
                {/* 投資組合：{buyNum}檔 */}

                <Typography sx={{ mt: 2, textAlign: "center" }} variant="h4">市場預期表
                    {/* <Button onClick={e => setOpenSetting1(true)}>設定</Button> */}
                    <IconButton onClick={e => setOpenSetting2(true)}>
                            <AddIcon />
                        </IconButton>
                </Typography>

                <TableContainer component={Paper} sx={{ position: 'absolute', top: Height.header + 60, bottom: props.ismobile || fullScreen ? 60: 0, mt: 1.5 }}>


                    {/* <Button onClick={e => setOpenSetting1(true)} fullWidth>總資金：{numberWithCommas(getCapital())}元，買進條件：市值前{getMC()}名...</Button> */}


                    <Table sx={{ minWidth: 700 }} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell>股票</StyledTableCell>
                                <StyledTableCell align="center">類股</StyledTableCell>
                                <StyledTableCell align="center">預期年</StyledTableCell>
                                <StyledTableCell align="center">預期價格</StyledTableCell>
                                <StyledTableCell align="center">折溢價</StyledTableCell>
                                <StyledTableCell align="center">最新營收YoY</StyledTableCell>

                                <StyledTableCell align="center">價格預估</StyledTableCell>
                                <StyledTableCell align="center">營收成長預估</StyledTableCell>
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row1) => {
                                let row = row1.ed

                                return <StyledTableRow key={row.company.stock_no}>
                                    <StyledTableCell onClick={e => openDetail2(row.company.stock_no)} >
                                        {row.company.short_name} {row.company.stock_no}

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.expect_year}年
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.expect_price}元
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button disableElevation fullWidth variant={getVariant2(row.dp)} color={getColor2(row.dp)}>{row.dp}%</Button>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography color={getColor3(row.cur_rev_yo_y)}>{row.cur_rev_yo_y}%</Typography>

                                    </StyledTableCell>

                                    <StyledTableCell>
                                        <RevTab value1={row.max_price} value2={row.avg_price}
                                            value3={row.min_price} unit={"元"}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <RevTab value1={row.max_rev} value2={row.avg_rev}
                                            value3={row.min_rev} unit={"%"}
                                        />
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                        <Stack direction={"row"} spacing={0.1}>
                                            <Button onClick={e => clickSetting(row1)} disableElevation variant='contained' sx={{
                                                bgcolor: grey[600], ':hover': {
                                                    bgcolor: grey[600], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>設定</Button>
                                            <Button onClick={e => removeLove(row)} disableElevation variant='contained' sx={{
                                                bgcolor: grey[600], ':hover': {
                                                    bgcolor: grey[600], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>刪除</Button>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>} */}
        </>
    );
}
