import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';


export default function AlertD(props) {
    const [open, setOpen] = React.useState(false);

   const handleClose = () => {
        setOpen(false);
        if(props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    提醒
                </DialogTitle>
                <DialogContent sx={{  }}>
                    {props.desc}
                </DialogContent>
                <DialogActions>
                    <Button disableElevation variant='contained' onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
