import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'
import ExpectHome from "./expect/chome/CHome"

export default props => (
  <HashRouter>
    <Routes>
      <Route exact path='/' element={<ExpectHome pathname={"home"}/>} />
      <Route exact path='/ghome' element={<ExpectHome pathname={"google"}/>} />
      <Route exact path='/ioshome' element={<ExpectHome pathname={"ios"}/>} />                       
    </Routes>
  </HashRouter>
)