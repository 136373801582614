const MAWeek = [
    {id: "3", value: "3週"},    
    {id: "5", value: "5週"},
    {id: "7", value: "7週"},       
    {id: "10", value: "10週"},       
    {id: "20", value: "20週"},
    {id: "30", value: "30週"},
    {id: "50", value: "50週"},
    {id: "100", value: "100週"},
    {id: "150", value: "150週"},
    {id: "200", value: "200週"},                 
  ];  
  
  export default MAWeek