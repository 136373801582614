const NumberN10 = [
    {id: "0", value: "0"},
    {id: "-1", value: "-1"},
    {id: "-2", value: "-2"},
    {id: "-3", value: "-3"},
    {id: "-4", value: "-4"},                
    {id: "-5", value: "-5"},
    {id: "-6", value: "-6"},
    {id: "-7", value: "-7"},
    {id: "-8", value: "-8"},
    {id: "-9", value: "-9"},                    
    {id: "-10", value: "-10"},    
    {id: "-15", value: "-15"},       
    {id: "-20", value: "-20"},
    {id: "-25", value: "-25"},    
    {id: "-30", value: "-30"},
    {id: "-35", value: "-35"},    
    {id: "-40", value: "-40"},
    {id: "-45", value: "-45"},
    {id: "-50", value: "-50"},
    {id: "-55", value: "-55"},    
    {id: "-60", value: "-60"},
    {id: "-65", value: "-65"},    
    {id: "-70", value: "-70"},
    {id: "-75", value: "-75"},
    {id: "-80", value: "-80"},  
    {id: "-85", value: "-85"},    
    {id: "-90", value: "-90"},
    {id: "-95", value: "-95"},
    {id: "-96", value: "-96"},
    {id: "-97", value: "-97"},
    {id: "-98", value: "-98"},
    {id: "-99", value: "-99"},                    
    {id: "-100", value: "-100"},
  ];  
  
  export default NumberN10  