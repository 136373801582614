import React, { useEffect, useRef } from 'react';
// import { createTheme, ThemeProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Routes from './Routes'
import ColorModeContext from './util/ColorMode'
// import {fpPromise} from './util/BasicInfo'
import { getId, getLic } from './util/ID'
import { grey } from '@mui/material/colors';

function MobileApp1() {
    // const [mode, setMode] = React.useState('light');
    const [mode, setMode] = React.useState(localStorage.getItem("colormode") != null ? localStorage.getItem("colormode") : 'light');

    useEffect(() => {
    }, []);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    let curMode = prevMode === 'light' ? 'dark' : 'light'
                    localStorage.setItem("colormode", curMode)
                    return curMode
                });
            },
        }),
        [],
    );


    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    neutral: {
                        main: '#E9EBE6',
                        contrastText: '#fff',
                    },
                    primary: {
                        main: "#3c7899"// "#"
                    },
                    secondary: {
                        main: grey[300]
                    },

                    mode: mode
                },
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Routes />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );

}

export default MobileApp1;
